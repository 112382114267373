@import '../../scss/config';

.single-driver-page {
  .info-stats-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 22px;

    .actions-stats-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .actions-wrapper {
      margin-bottom: 22px;
      display: flex;
      justify-content: flex-end;
      gap: 22px;
    }

    .stats-wrapper {
      flex: 1;
      display: grid;
      gap: 14px;
      grid-template-columns: repeat(auto-fit, minmax(232px, 1fr));
      .statistics-card {
        height: fit-content;
      }
    }
  }

  .car-front-back-img {
    display: flex;
    gap: 22px;
    margin-top: 22px;
    background-color: $white;
    border-radius: 8px;
    padding: 20px 20px;
    box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
    .img-btn-wrap {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .img-label {
      }
    }
    .img-preview-btn {
      padding: 2px;
      flex: 1;
      display: flex;
      border: 0;
      outline: 0;
      background-color: #eee;
      align-items: center;
      justify-content: center;
      border-radius: 6px;

      .car-img {
        svg {
          width: 82px;
        }
      }
      img {
        width: 100%;
        border-radius: 5px;
      }
      svg {
        width: 82px;
        height: 8px;
      }
    }
  }
}
