@import '../../../scss/config';

.home-messages-list {
  background-color: $white;
  background-color: #f7f8fa;
  padding: 18px;
  border-radius: 12px;
  box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
  display: grid;
  gap: 14px;

  .list-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    p {
      font-size: 18px;
    }
    a {
      color: $secondary-text-color;
      text-decoration: underline;
    }
  }
}
