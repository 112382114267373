@import './config';

html {
  &[lang='ar'] {
    .bold-font {
      @include headingFont-ar;
      color: $main-app-color;
    }
  }
  &[lang='en'] {
    .bold-font {
      @include headingFont-en;
      color: $main-app-color;
    }
  }
}

.custom-slick-slider {
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    font-size: 16px;
    width: 38px;
    height: 38px;
    cursor: pointer;
    // border: 1px solid $main-app-color;
    border-radius: 50%;
    outline: none;
    background: transparent;
    background-color: #fff;
    display: grid;
    place-items: center;
    z-index: 1;
    &::before {
      content: '';
    }
    .btn-content {
      position: relative;
      display: flex;
      place-content: center;
      align-items: center;
      svg {
        width: 11px;
        height: 11px;
      }
    }
    @include mediaXlg {
      width: 52px;
      height: 52px;
      .btn-content {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  .slick-prev {
    left: -18px;
    right: auto;
    @include mediaXlg {
      left: -26px;
    }
  }
  .slick-next {
    // left: calc(12px + 52px);
    right: -18px;
    left: auto;
    @include mediaXlg {
      right: -26px;
    }
  }

  .slick-dots {
    bottom: 22px;
    li {
      button {
        &::before {
          font-size: 14px;
          width: 16px;
          height: 16px;
          border: 1px solid $main-app-color;
          border-radius: 50%;
          line-height: 16px;
        }
      }
    }
  }
}

// .ant-modal-root .ant-modal-wrap {
//   z-index: 29387982739;
// }

.ant-modal-wrap {
  overflow-y: hidden !important;
  .ant-modal {
    top: 5vh;
  }
}
.shared-custom-modal {
  .ant-modal-content {
    padding: 0 !important;
  }

  .ant-modal-body {
    padding: 12px 6px 22px 6px;
    .custom-modal-content {
      padding: 0 16px;
      overflow-y: auto;
      @include custom-scrollbar;
      // min-height: 30vh;
      max-height: calc(100vh - 5vh - 62px - 10vh);
    }
  }

  .ant-modal-header {
    min-height: 62px;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    padding: 8px 62px;
    display: grid;
    align-items: center;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 20px;
    .modal-title {
      font-size: 20px;
      color: #404041;

      .svg-wrap {
        background-color: #f9f8f8;
        width: 46px;
        height: 46px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        svg {
          width: 22px;
          height: 22px;
          color: #404041;
        }
      }
    }
  }
  .ant-modal-content {
    border-radius: 15px;
    overflow: hidden;

    .ant-modal-close {
      &:hover {
        .anticon {
          border-color: rgba(#000, 0.4);
        }
      }
      .anticon {
        border: 1px solid #404041;
        padding: 4px;
        border-radius: 50%;
        svg {
          width: 14px;
          height: 14px;
          color: #404041;
        }
      }
    }
  }

  h3 {
    margin: 22px auto;
    margin-top: 42px;
    text-align: center;
  }

  &.success-modal {
    .accept-btn {
      background-color: $main-app-color;
    }
  }
  &.error-modal {
    h3 {
      color: #d62d20;
    }
    .accept-btn {
      background-color: #d62d20;
    }
  }

  .table-search {
    // border: 1px solid rgba(#000, 0.2);
    display: grid;
    background-color: #f9f8f8;
    border-radius: 20px 5px 20px 5px;
    label {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      padding: 8px 12px;
      height: 58px;
    }
    input {
      border: 0;
      outline: 0;
      margin-right: 8px;
      background-color: #f9f8f8;
    }
  }
}
.modal-action-btns-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 22px;
  margin-top: 42px;
  .accept-btn,
  .reject-btn,
  .cancel-btn {
    display: flex;
    gap: 8px;
    align-items: center;
    border: 0;
    outline: 0;
    height: 40px;
    padding: 4px 22px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.15s ease-out;
    &:hover {
      box-shadow: 1px 2px 2px rgba(#000, 0.1);
    }
  }
  .accept-btn {
    background-color: $main-app-color;
    color: #fff;
  }

  .reject-btn {
    background-color: #fff;
    color: #fff;
  }

  .cancel-btn {
    background-color: #ddd;
    color: #404041;
  }
}
.ant-form .ant-form-item {
  flex-wrap: nowrap !important;
}
// .ant-form .ant-form-item .ant-form-item-label {
//   flex: 0 0 50%;
// }

.custom-filter-add-section,
.custom-filter-add-section1 {
  margin-top: 52px;
  margin-bottom: 10px;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: end;
  gap: 52px;
  &.custom-filter-add-section1 {
    grid-auto-flow: row;
    gap: 12px;
    .add-section {
      .add-btn {
        padding: 9px 32px;
        border-radius: 12px;
        min-height: 48px;
      }
    }
  }
  .add-section {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 14px;
    align-items: center;
    p {
      &:nth-of-type(1) {
        font-size: 20px;
      }
      &:nth-of-type(2) {
        font-size: 16px;
      }
    }

    .add-btn {
      align-self: flex-end;
      background-color: $main-app-color;
      color: #fff;
      padding: 5.5px 28px;
      border-radius: 8px;
      min-height: 40px;
      display: grid;
      align-items: center;
      cursor: pointer;
      .text-span {
        margin-bottom: 4px;
      }
    }
  }
}

.custom-table-fitler {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  .filter-form {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
    align-items: flex-end;

    .table-search {
      border: 1px solid rgba(#000, 0.2);
      border-radius: 8px;
      display: grid;
      label {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        padding: 8px 12px;
        height: 40px;
      }
      input {
        border: 0;
        outline: 0;
        margin-right: 8px;
        background-color: transparent;
      }
    }

    .receipt-filter-option-wrap {
      display: grid;
      gap: 4px;
      .ant-select-selector {
        border: 1px solid rgba(#000, 0.1);
        background-color: $main-bg-color;
        background-color: transparent;
        height: 44px;
        height: 100%;
        border-radius: 8px;
        input {
          height: 100%;
        }
        &::after {
          line-height: 44px !important;
        }
      }
      .ant-select-selection-item {
        line-height: 44px;
      }
      svg {
        width: 8px;
        height: 8px;
        margin-bottom: 1px;
        path {
          fill: #333;
        }
      }
      .select-title {
        color: #888;
        font-size: 16px;
        margin-bottom: 4px;
      }
    }

    &.receipt-filter-form {
      // display: flex;
      // grid-auto-flow: column;
      // .filter-submit-btn {
      //   align-self: end;
      // }
    }

    .filter-range-picker {
      background-color: $main-bg-color;
      border-radius: 8px;
      border: 0;
      height: 40px;
    }
    .filter-submit-btn {
      background-color: $main-bg-color;
      border: 1px solid rgba(#000, 0.8);
      height: 36px;
      padding: 2px 28px;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.1s ease-out;
      &:hover {
        background-color: $main-app-color;
        color: #fff;
      }
    }
  }
  .excel-export-btn {
    cursor: pointer;
    img {
      height: 44px;
      border-radius: 8px;
    }
  }
}

.custom-circled-icon {
  background-color: #f9f8f8;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  svg {
    width: 22px;
    height: 22px;
  }
}

.success-order-modal {
  display: grid;
  place-items: center;
  .modal-body {
    padding: 18px 14px;
    display: grid;
    place-content: center;
    position: absolute;
    background-color: #f7f8fa;
    width: 94%;
    max-width: 600px;
    min-height: 360px;
    @include mediaLg {
      min-height: 400px;
    }
    box-shadow: 0 12px 14px rgba(#000, 0.1), 0 0 30px rgba(#000, 0.1);
    border-radius: 4px;
    border: 0;
    outline: 0;

    img {
      width: 182px;
    }
    p {
      margin-top: 18px;
      text-align: center;
      font-size: 20px;
    }

    .cart-link {
      background-color: $main-app-color;
      color: #fff;
      width: fit-content;
      justify-self: center;
      margin-top: 18px;
      border-radius: 4px;
      padding: 5px 18px;
      font-size: 15px;
      display: flex;
      gap: 6px;
      align-items: center;
      transition: all 0.2s ease-out;
      &:hover {
        background-color: darken($main-app-color, 8%);
        box-shadow: 0 8px 12px rgba(#000, 0.1);
        color: #fff;
      }
      svg {
        width: 15px;
        height: 15px;
        path {
          stroke: #fff;
        }
      }
    }
  }
}

$green: #008744;
$blue: #0057e7;
$red: #d62d20;
$yellow: #ffa700;
$smoke: #eee;

// scaling... any units
$width: 42px;

.common-loader {
  position: relative;
  margin: 0 auto;
  width: $width;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes color {
    100%,
    0% {
      stroke: $red;
    }
    40% {
      stroke: $blue;
    }
    66% {
      stroke: $green;
    }
    80%,
    90% {
      stroke: $yellow;
    }
  }
}

.currency-label {
  color: $main-app-color;
}

.shared-delete-modal-wrap {
  display: grid;
  place-items: center;
  .ant-modal {
    top: 0;
  }
}
.shared-delete-modal {
  .ant-modal-body {
    padding: 62px 24px;
    text-align: center;
    .modal-content {
      color: #ed0006;
      display: grid;
      place-items: center;
      img {
        margin-bottom: 12px;
      }
      p {
        font-size: 22px;
      }
    }
    .modal-btns {
      margin-top: 22px;
      display: flex;
      gap: 22px;
      button {
        border: 0;
        outline: 0;
        padding: 10px 22px;
        border-radius: 4px;
        height: auto !important;
        font-size: 18px;
      }
      .submit-btn {
        background-color: #ed0006;
        color: #fff;
      }

      .cancel-btn {
        background-color: #eaebed;
      }
    }
  }
}
