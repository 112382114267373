@import '../../../scss/config';

.driver-car-info-card {
  margin-top: 22px;
  background-color: $white;
  border-radius: 8px;
  padding: 20px 20px;
  box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);

  .card-header {
    font-weight: bold;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(#000, 0.1);
    padding-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
  }

  .card-info {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }
  .info-row {
    &.car-type {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    span {
      &:first-of-type {
        font-weight: bold;
      }
      &:last-of-type {
        color: $secondary-text-color;
        font-size: 18px;
      }
    }
  }
}
