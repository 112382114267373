@import '../../../scss/config';

.statistics-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  // background-color: #f3f6f9;
  background-color: $white;
  border-radius: 5px;
  // max-width: 300px;
  padding: 20px 20px;
  box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);

  .icon-wrap {
    width: 62px;
    height: 62px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lighten($main-app-color, 30%);
    .svg-icon {
      width: 28px;
      height: 28px;

      .fill-path {
        fill: $main-app-color;
      }
    }
  }
  .card-body {
    .card-title {
      text-transform: capitalize;
      font-size: 15px;
      margin-bottom: 12px;
      color: $secondary-text-color;
      // font-weight: lighter;
    }

    .card-value {
      .value-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;
      }
      p {
        display: flex;
        gap: 4px;
        align-items: flex-end;
        font-size: 22px;
        font-weight: bold;
      }
      .curr-span {
        font-size: 12px;
      }
    }
  }

  &:nth-of-type(1) {
    .icon-wrap {
      background-color: #effbe8;
      .svg-icon {
        .fill-path {
          fill: #72dd38;
        }
      }
    }
  }
  &:nth-of-type(2) {
    .icon-wrap {
      background-color: #e7f8fc;
      .svg-icon {
        .fill-path {
          fill: #03c3ed;
        }
      }
    }
  }
  &:nth-of-type(3) {
    .icon-wrap {
      background-color: #ededff;
      .svg-icon {
        .fill-path {
          fill: #6a6cff;
        }
      }
    }
  }
  &:nth-of-type(4) {
    .icon-wrap {
      background-color: #fff5e5;
      .svg-icon {
        .fill-path {
          fill: #ffac04;
        }
      }
    }
  }
  &:nth-of-type(5) {
    .icon-wrap {
      background-color: #effbe8;
      .svg-icon {
        .fill-path {
          fill: #72dd38;
        }
      }
    }
  }
}
