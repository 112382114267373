@import '../../scss/config';

.admin-home-page {
  padding-bottom: 42px;
  .two-cols-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 22px;
  }

  .orders-table-home-wrap {
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
    padding: 18px;
    margin-top: 22px;
    margin-bottom: 22px;
    .table-header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;
      p {
        font-size: 18px;
      }
      a {
        color: $secondary-text-color;
        text-decoration: underline;
      }
    }
  }

  .two-rows-col {
    display: grid;
    gap: 22px;
    grid-template-rows: 1fr 1fr;
  }

  .chart-card {
    background-color: $white;
    background-color: #f7f8fa;
    // padding: 12px;
    border-radius: 12px;
    box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);

    .chart-header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 18px;
      p {
        font-size: 18px;
      }
      a {
        color: $secondary-text-color;
        text-decoration: underline;
      }
    }

    .chart-body {
      padding: 18px;
      padding-top: 0;

      .antd-area-chart {
        width: 100%;
      }
    }
  }
}
