@import '../../scss/config';

.app-menu-wrapper-parent {
  padding: 14px 0;
  border-inline-end: 1px solid #eee;
  background-color: #f7f8fa;
  position: sticky;
  top: 0;
  z-index: 1;
  height: 100vh;
  transition: all 0.2s linear;
  &::after {
    content: '';
  }

  &.ltr {
    .react-resizable-handle {
      background-image: none;
      background-color: transparent;
      height: 100%;
      width: 6px;
      transform: none;
      top: 0;
      right: -3px;
      left: auto;
    }
  }
  &.rtl {
    .react-resizable-handle {
      background-image: none;
      background-color: transparent;
      height: 100%;
      width: 6px;
      transform: none;
      top: 0;
      left: -3px;
      right: auto;
    }
  }
}

.app-menu-wrapper {
  height: calc(100vh - 28px);
  overflow-y: auto;
  @include custom-scrollbar;
  // padding-inline-end: 4px;
  &.collapsed {
    .app-logo {
      img {
        width: 48px;
      }
    }
  }
  .app-logo {
    padding: 0 18px;
    padding-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 80px;
      height: auto;
      transition: all 0.15s linear;
    }
  }

  .app-menu {
    // .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    //   background-color: lighten($main-app-color, 12%);
    //   color: $main-text-color;
    // }

    .ant-menu-item {
      // display: flex !important;
      display: flex;
      align-items: center;
      // align-items: center;
      // gap: 8px;
    }
    &.ant-menu-inline-collapsed {
      .ant-menu-item {
        .ant-menu-title-content {
          display: none;
        }
      }
    }

    .ant-menu-submenu-title {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
