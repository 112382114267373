@import '../../scss/config';

$appBarHeight: 60px;

.app-top-bar {
  padding: 4px 0;
  // border-bottom: 1px solid rgba(#000, 0.1);
  display: flex;
  align-items: center;
  height: $appBarHeight;
  border-radius: 8px;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: $white;
  transition: all 0.2s ease-out;

  .app-bar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .app-menu-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    overflow: hidden;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    .svg-wrap {
      display: flex;
      align-items: center;
      justify-content: center;

      &.arrow-wrap {
        animation: s2 0.6s ease-out;
      }
      &.menu-wrap {
        animation: s1 0.6s ease-out;
      }
    }
    @keyframes s1 {
      0% {
        opacity: 0;
        transform: translateX(-100%) rotate(180deg) scale(0.2);
      }
      100% {
        opacity: 1;
        transform: translateX(0) scale(1);
      }
    }
    @keyframes s2 {
      0% {
        opacity: 0;
        transform: translateX(-100%) rotate(-360deg) scale(0.2);
      }
      100% {
        opacity: 1;
        transform: translateX(0) rotate(0) scale(1);
      }
    }
  }

  .bar-actions-wrap {
    display: flex;
    align-items: center;
    gap: 22px;
    flex-wrap: wrap;
    .main-lang-btn {
      margin-inline-end: -8px;
    }
    .full-screen-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .date-p {
      font-size: 14px;
      font-weight: bold;
    }
  }

  // handle scroll
  &.exceeds0 {
    box-shadow: 0 4px 8px rgba(#000, 0.1);
  }

  &.scroll-down {
    transform: translateY(-$appBarHeight);
  }
}
