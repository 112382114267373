$main-app-color: #d0ae6c;
$secondary-app-color: #58b030;
$secondary-background-color: #f7f8fa;
// $secondary-background-color: #e5e5e5;
// $body-background-color: #f8f9fa;
$body-background-color: #fff;
// $body-background-color: #eee;
// $secondary-app-color: #f9ec14;
$main-text-color: #000000e0;
$secondary-text-color: #00000073;
$main-bg-color: #f1f2f3;
$white: #fff;

// accounting colors
$level-one-color: #f1c40f;
$level-two-color: #e17055;
$level-three-color: #00b894;
$level-four-color: #6c5ce7;
$level-five-color: #f1f2f3;

$headerMd-height: calc(32px + 32px);
$headerLg-height: calc(52px + 48px + 42px);
$headerXlg-height: calc(52px + 48px + 42px);
$headerXXlg-height: calc(52px + 48px + 42px);
// $headerXXlg-height: calc(62px + 62px + 42px);

@mixin mainAppBarHeight {
  height: $headerMd-height;
  @include mediaLg {
    height: $headerLg-height;
  }
  @include mediaXlg {
    height: $headerXlg-height;
  }
  @include mediaXXlg {
    height: $headerXXlg-height;
  }
}

@mixin heroHeight {
  // max-height: calc(70vh - #{$headerMd-height} - 52px);
  height: calc(100vh - #{$headerMd-height});
  @include mediaLg {
    height: calc(100vh - #{$headerLg-height});
  }
  @include mediaXlg {
    height: calc(100vh - #{$headerXlg-height});
  }
  // @include mediaXlg {
  //   max-height: calc(70vh - #{$headerXXlg-height});
  // }
  @include mediaXXlg {
    height: calc(100vh - #{$headerXXlg-height});
  }
}

@mixin minusMainAppBarHeight {
  top: -$headerMd-height;
  @include mediaLg {
    top: -$headerLg-height;
  }
  @include mediaXlg {
    top: -$headerXlg-height;
  }
  @include mediaXXlg() {
    top: -$headerXXlg-height;
  }
}

$app-header-z-index: 99;
$backdrop-z-index: 100;
$dropDownMenu-z-index: 200;
$focusedTextArea-z-index: 300;
$miniDropdown-z-index: 400;
$modalWrapper-z-index: 500;
$postModalWrapper-z-index: 499;

@mixin customPaper {
  border-radius: 4px;
  .myPaperHeader {
    padding: 14px 16px;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: start;
    grid-gap: 6px;
    font-size: 20px;
    border-radius: 4px 4px 0 0;
  }

  &.black {
    .myPaperHeader {
      background-color: #151515;
      color: $black-primary-color;
    }
  }
  &.light {
    .myPaperHeader {
      background-color: $white-primary-color;
      color: #fff;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

// ::selection {
// 	background-color: rgba(#3fd4e2, 0.4);
// 	color: #424242;
// }
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

a {
  color: $main-text-color;
}

a,
a:hover {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@font-face {
  font-family: 'IBMPlexSans-Light';
  src: url(../assets/fonts/IBMPlexSans-Light.woff2) format('woff2');
  font-style: normal;
}
@font-face {
  font-family: 'IBMPlexSans-Regular';
  src: url(../assets/fonts/IBMPlexSans-Regular.woff2) format('woff2');
  font-style: normal;
}
@font-face {
  font-family: 'IBMPlexSans-Bold';
  src: url(../assets/fonts/IBMPlexSans-Bold.woff2) format('woff2');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'IBMPlexSansArabic-Light';
  src: url(../assets/fonts/IBMPlexSansArabic-Light.woff2) format('woff2');
  font-style: normal;
}
@font-face {
  font-family: 'IBMPlexSansArabic-Regular';
  src: url(../assets/fonts/IBMPlexSansArabic-Regular.woff2) format('woff2');
  font-style: normal;
}
@font-face {
  font-family: 'IBMPlexSansArabic-Bold';
  src: url(../assets/fonts/IBMPlexSansArabic-Bold.woff2) format('woff2');
  font-style: normal;
  font-weight: bold;
}

@mixin bodyFont {
  font-family: 'IBMPlexSans-Regular', 'IBMPlexSansArabic-Regular';
  // font-family: 'Quicksand_Book', 'DroidKufi-Regular' !important;
  // font-family: 'Quicksand_Book', 'Poppins-Regular' !important;
}

@mixin headingFont-ar {
  // font-family: 'DroidSans-Bold';
  // font-family: 'Quicksand_Bold', 'DroidKufi-Regular' !important;
  font-family: 'IBMPlexSans-Bold', 'IBMPlexSansArabic-Bold';
  font-weight: bold;
}
@mixin headingFont-en {
  // font-family: 'DroidSans-Bold';
  // font-family: 'DroidKufi-Regular', 'Poppins-Regular' !important;
  // font-family: 'Quicksand_Bold', 'DroidKufi-Regular' !important;
  font-family: 'IBMPlexSans-Bold', 'IBMPlexSansArabic-Bold';
  font-weight: bold;
}
// @mixin bodyFont-en {
// 	font-family: "AndikaNewBasic-Regular" !important;
// }

// @mixin headingFont-ar {
// 	font-family: "Janna-LT-Bold";
// }
// @mixin headingFont-en {
// 	font-family: "AndikaNewBasic-Bold";
// }

@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #d5d5d5;
    background-color: #ddd;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5f8b9e8f;
    background-color: #b5b5b5;
    background-color: #bebebe;
    border-radius: 10px;

    &:hover {
      background: #aaa;
    }
  }

  // &:hover {
  // 	&::-webkit-scrollbar-track {
  // 		box-shadow: inset 0 0 1px grey;
  // 		background-color: #f7f8fa;
  // 	}

  // 	&::-webkit-scrollbar-thumb {
  // 		background: #ababab;
  // 		background: red;
  // 	}
  // }
}

// custom break points
@mixin mediaXsm {
  @media only screen and (min-width: 320px) {
    @content;
  }
}

@mixin mediaSm {
  @media only screen and (min-width: 375px) {
    @content;
  }
}

@mixin mediaSmd {
  @media only screen and (min-width: 576px) {
    @content;
  }
}

@mixin mediaMd {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin mediaLg {
  @media only screen and (min-width: 992px) {
    @content;
  }
}

@mixin mediaLLg {
  @media only screen and (min-width: 1224px) {
    @content;
  }
}

@mixin mediaXlg {
  // @media only screen and (min-width: 1200px) {
  @media only screen and (min-width: 1366px) {
    @content;
  }
}

@mixin mediaXXlg {
  @media only screen and (min-width: 1400px) {
    @content;
  }
}
@mixin mediaXXXlg {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}
@mixin mediaXXXXlg {
  @media only screen and (min-width: 1920px) {
    @content;
  }
}

// @mixin mediaXXlg {
//   @media only screen and (min-width: 1600px) {
//     @content;
//   }
// }

.custom-container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;

  @include mediaSmd {
    max-width: 540px;
  }

  @include mediaMd {
    max-width: 720px;
  }

  @include mediaLg {
    max-width: 960px;
  }
  @include mediaLLg {
    max-width: 1170px;
    // max-width: 1300px;
  }

  @include mediaXlg {
    max-width: 1282px;
    // max-width: 1322px;
  }

  @include mediaXXlg {
    max-width: 1362px;
    // max-width: 1280px;
  }
  @include mediaXXXlg {
    max-width: 1540px;
    // max-width: 1280px;
  }
  @include mediaXXXXlg() {
    max-width: 1740px;
    // max-width: 1280px;
  }
}

@mixin topBottomAnimation {
  animation: topBottomAnimation 3s linear infinite;

  @keyframes topBottomAnimation {
    0%,
    100% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(6px);
    }
    30% {
      transform: translateY(6px);
    }
    75% {
      transform: translateY(-6px);
    }
    80% {
      transform: translateY(-6px);
    }
  }
}
@mixin bounce_animation {
  animation: bounce_me 3s linear infinite;

  @keyframes bounce_me {
    0%,
    100% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(3px);
    }
    32% {
      transform: translateY(3px);
    }
    75% {
      transform: translateY(-3px);
    }
    82% {
      transform: translateY(-3px);
    }
  }
}

// page shared styles
.myPage {
  min-height: 100vh;
  padding-top: $headerMd-height;
  @include mediaLg {
    padding-top: $headerLg-height;
  }
}
