@import '../../../scss/config';

.single-order-page {
  .customer-store-wrapper {
    display: grid;
    gap: 22px;
    @include mediaLg {
      grid-template-columns: 1fr 1fr;
    }
  }

  .prices-desc-wrapper {
    display: grid;
    @include mediaLg {
      grid-template-columns: 1.15fr 1fr;
    }
    .summary-title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      color: $secondary-text-color;
      text-transform: capitalize;
    }
  }
}
