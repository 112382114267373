@import '../../../scss/config';
.withdrawals-table {
  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 20px - 2px);
  }

  .ant-switch-rtl.ant-switch-checked .ant-switch-handle {
    right: calc(100% - 20px - 2px);
  }
  .affiliate-toggle {
    background-color: #25d366;
    border-radius: 4px;
    height: 24px;
    margin: 0 32px;
    .ant-switch-handle {
      &::before {
        border-radius: 4px !important;
        width: 20px;
        height: 20px;
      }
    }
    &.not-active {
      background-color: gray;
    }
  }

  .action-btns-wrap {
    gap: 12px !important;
    button {
      border: 1px solid;
      border-radius: 8px;
      height: 36px;
      cursor: pointer;
      &.accept-btn {
        background-color: rgb(200, 243, 200);
        color: green;
        border-color: rgb(144, 218, 144);
      }
      &.reject-btn {
        background-color: #ffebeb;
        color: red;
        border-color: rgb(232, 189, 189);
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }
}
