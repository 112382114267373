@import '../../../../scss/config';

.order-customer-info {
  border-top: 1px solid rgba(#000, 0.1);
  margin-top: 12px;
  padding-top: 12px;

  .desc-map-wrapper {
    display: grid;
    gap: 16px;
    @include mediaLg {
      grid-template-columns: 1fr 1fr;
      .map-wrapper {
        margin-top: 4px;
        height: fit-content;
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }

  .ant-descriptions-header {
    margin-bottom: 8px;
  }
}
