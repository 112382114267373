.range-methods-table {
  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 20px - 2px);
  }

  .ant-switch-rtl.ant-switch-checked .ant-switch-handle {
    right: calc(100% - 20px - 2px);
  }
  .affiliate-toggle {
    background-color: #25d366;
    border-radius: 4px;
    height: 24px;
    margin: 0 32px;
    .ant-switch-handle {
      &::before {
        border-radius: 4px !important;
        width: 20px;
        height: 20px;
      }
    }
    &.not-active {
      background-color: gray;
    }
  }
}
