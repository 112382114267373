@import '../../scss/config';

.login-page {
  background-color: #f5f5f5;
  display: grid;
  // place-items: center;
  .login-form-section {
    display: grid;
    justify-items: center;
    align-content: center;
    min-height: 100vh;
  }

  .logo {
    margin-bottom: 18px;
    img {
      width: 162px;
    }
  }
  .copyrights {
    color: #c4c4c4;
    text-align: center;
  }

  .page-wrapper {
    padding: 42px 0;
    min-height: 100vh;
    display: grid;
    grid-gap: 12px;
    align-content: center;
    justify-items: center;

    .logo-itself {
      height: 48px;
      margin-bottom: 22px;
    }
    .img-wrap {
      display: grid;
      position: relative;
      align-self: end;
      .main-img {
        width: 100%;
        max-width: 352px;
        object-fit: cover;
        object-position: bottom center;
      }
    }

    .form-top-level-wrapper {
      display: grid;
      justify-items: center;
    }
  }
}
