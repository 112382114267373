.image-modal {
  max-width: 94%;
  // .ant-modal-body {
  //   padding: 1rem;
  // }
  .modal-img {
    display: flex;
    justify-content: center;
    align-items: center;

    img,
    svg {
      max-width: 100%;
    }
    img {
      border-radius: 5px;
    }
  }
}
