.delete-btn-wrapper {
  .delete-btn {
    background-color: rgb(255, 205, 205);
    color: red;
    border: 0;
    outline: 0;
    border-radius: 6px;
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 9px 22px;
    min-height: 42px;
    cursor: pointer;
    .svg-icon {
      .fill-path {
        fill: red;
      }
    }
  }
}
