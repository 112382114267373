@import '../../scss/config';

.signin-form {
  width: 100%;
  // min-width: 448px;
  display: grid;
  height: max-content;
  background-color: #fff;
  max-width: 532px !important;
  align-self: center;
  align-self: center;
  // box-shadow: 0 24px 28px rgba(#000, 0.1);
  padding: 22px 0 !important;
  margin-bottom: 32px !important;
  box-shadow: 0px 0px 25px 10px #f8f8fb;
  border-radius: 16px;
  @include mediaLg {
    padding: 38px 0 !important;
    margin-bottom: 32px !important;
  }

  .form-header {
    margin-bottom: 12px;
    .form-logo {
      display: grid;
      padding: 32px;
      padding-bottom: 8px;
      place-items: center;
      img {
        max-width: 100%;
        height: 52px;
      }
    }
    p {
      text-align: center;
      color: #888;
      font-size: 16px;
      @include mediaLg {
        font-size: 20px;
      }
      margin-bottom: 22px;
      max-width: 80%;
      margin: auto;
      margin-bottom: 18px;

      &.main-title {
        color: $main-app-color;
        font-size: 28px;
        margin-bottom: 6px;
      }
      &.sub-title {
        margin-bottom: 4px;
        font-size: 16px;
        color: #c4c4c4;
      }
    }
    a {
      font-size: 18px;
      font-weight: bold;
      color: $main-app-color;
      text-decoration: underline;
    }
  }

  .forget-p {
    color: #58b030;
    cursor: pointer;
    margin-bottom: 18px;
  }

  .form-body {
    padding: 18px 22px;
    display: grid;
    @include mediaLg {
      padding: 22px 42px;
    }

    .ant-checkbox-wrapper {
      color: #777;
      font-size: 16px;
    }

    .login-password-field-wrap {
      position: relative;
      &.with-eye-icon {
        input {
          padding-left: 42px !important;
        }
      }
      .eye-icon-btn {
        background-color: transparent;
        height: fit-content;
        border: 0;
        outline: 0;
        position: absolute;
        display: flex;
        align-items: center;
        left: 14px;
        top: 21%;
        cursor: pointer;
        padding: 4px;
        z-index: 1;
        img {
          height: 18px;
        }
      }
    }
  }

  .dont-have-account-text {
    display: flex;
    gap: 6px;
    justify-content: center;
    margin: 0 12px;
    a {
      color: $main-app-color;
    }
  }
}
