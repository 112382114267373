@import '../../../../scss/config';

.country-info-card {
  background-color: $white;
  border-radius: 8px;
  padding: 20px 20px;
  box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
  .img-actions-wrapper {
    padding-bottom: 22px;
    margin-bottom: 22px;
    border-bottom: 1px dashed rgba(#000, 0.2);
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    justify-content: space-between;

    .actions-wrapper {
      margin-bottom: 22px;
      display: flex;
      justify-content: flex-end;
      gap: 22px;
      height: fit-content;
      align-items: flex-end;
    }
  }
  .img-name-status-wrap {
    display: flex;
    gap: 18px;
    align-items: center;
    .img-preview-btn {
      border: 0;
      outline: 0;
      padding: 0;
      cursor: pointer;
      background-color: transparent;
    }
    .driver-img {
      background-color: #eee;
      border-radius: 4px;
      padding: 2px;
      width: 146px;
      // height: 146px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 38px;
      }
      img {
        width: 142px;
        // height: 142px;
        border-radius: 4px;
        object-fit: cover;
        object-position: top center;
      }
    }

    .name-status-date {
      .name {
        font-weight: bold;
        text-transform: capitalize;
        font-size: 28px;
        margin-bottom: 4px;
      }
      .date {
        color: $secondary-text-color;
        font-size: 14px;
      }
      .status-wrap {
        margin-top: 12px;
        width: fit-content;
      }
    }
  }

  .location-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: space-evenly;

    .col-wrap {
      text-align: center;
      .col-label {
        margin-bottom: 10px;
      }
    }
  }
}
