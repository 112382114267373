@import '../../../../scss/config';

.order-actions-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  margin-bottom: 22px;
  .order-id {
    font-size: 18px;
  }
  .actions {
    display: flex;
    gap: 12px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;

    .select-label-wrap {
      .select-label {
        display: none;
      }
    }

    .assign-driver-btn {
      background-color: #cbf4cb;
      color: #4da24d;
      border: 0;
      outline: 0;
      border-radius: 6px;
      display: flex;
      gap: 5px;
      align-items: center;
      padding: 9px 22px;
      min-height: 42px;
      cursor: pointer;
    }
  }
}
