@import '../../../scss/config';

.antd-custom-table {
  .ant-table-row {
    transition: all 0.2s ease-out;
    cursor: pointer;
    &:has(.ant-table-cell:hover) {
      box-shadow: 0 0 18px 0 inset rgba(#000, 0.08);
      .ant-table-cell {
        background-color: rgba($main-app-color, 0.12) !important;
      }
    }

    .ant-table-cell {
      transition: background-color 0.15s ease-out;
    }
  }
}
