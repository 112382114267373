.wallet-transactions-page {
  padding-bottom: 32px;

  .table-add-btn-filter-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 22px;
  }
}
