@import '../../../../scss/config';

.total-ribbon {
  padding: 8px 22px;
  .ribbon-text {
    display: grid;
    grid-auto-flow: column;
    gap: 5px;
    align-items: center;
  }
  .total-span {
    font-size: 22px;
    line-height: 1;
    color: $secondary-text-color;
  }
  .curr-span {
    font-size: 13px;
    color: $secondary-text-color;
    align-self: end;
    line-height: 1;
    margin-bottom: 3px;
  }
}

.new-order-form-wrapper {
  max-width: 1000px;
}
.new-order-form {
  position: relative;

  .form-body {
    // .custom-radios-wrap {
    //   // display: flex;
    //   // gap: 18px;
    //   .radios-label {
    //   }
    // }

    .form-header {
      margin-bottom: 10px !important;
      font-size: 20px !important;
    }

    .main-form-fields {
      background-color: #f8f8f8;
      border: 1px solid #d9d9d9;
      padding: 20px;
      border-radius: 12px;
      margin-bottom: 22px;

      .prices-wrapper {
        display: grid;
        gap: 12px;
        @include mediaLg {
          grid-template-columns: repeat(4, 1fr);
        }
      }

      .dates-wrapper {
        display: grid;
        gap: 12px;
        @include mediaLg {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    .order-id-radios-wrapper {
      display: grid;
      gap: 12px;
      @include mediaLg {
        grid-template-columns: 1fr 1fr;

        .two-radios-groups {
          display: grid;
          grid-template-columns: 1.5fr 1fr;
          gap: 12px;
        }
      }

      .custom-radios-wrap {
        background-color: #f7f8fa;
        border-radius: 16px;
        border: 1px solid #d9d9d9;
        height: 70px;
        margin-bottom: 12px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 8px 14px;
        .radios-label {
          font-size: 14px;
        }
        .radio-input-label {
          margin-bottom: 0;
          .label-span {
            font-size: 14px;
          }
          .svg-radio-icon-wrap {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .order-form-products-fields-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .add-product-btn {
        height: 42px;
        line-height: 42px;
        gap: 8px;
        display: flex;
        align-items: center;
        transition: all 0.15s ease-out;
        border: 0;
        outline: 0;
        border-radius: 8px;
        padding: 2px 28px;
        padding-inline-start: 20px;
        background-color: lighten($main-app-color, 10%);
        position: absolute;
        top: -74px;
        &.rtl {
          left: 0;
          right: auto;
        }
        &.ltr {
          left: auto;
          right: 0;
        }

        & > svg {
          width: 18px;
          height: 18px;
        }
      }

      .products-fields-number-wrapper {
        display: grid;
        grid-template-columns: 1fr auto;
        background-color: #f1f1f1;
        border: 1px solid #dedede;
        border-radius: 16px;
        padding: 12px;
        position: relative;
        // padding-inline-start: 18px;
        .number-wrap {
          background-color: #f1f1f1;
          position: absolute;
          width: 62px;
          height: 82px;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 50%;
          transform: translateY(-50%);
          direction: ltr;
          font-size: 24px;
          color: rgba(#000, 0.4);
          font-weight: bold;
          &.rtl {
            right: -46px;
            left: auto;
          }
          &.ltr {
            right: auto;
            left: -46px;
          }
        }
        .fields-wrapper {
          display: grid;
          position: relative;
          z-index: 1;

          .name-qty-price-delete {
            display: grid;
            gap: 1px 14px;
            @include mediaLg {
              grid-template-columns: 1fr 1fr;
            }
            .qty-price {
              display: grid;
              grid-template-columns: 1fr 1fr auto;
              gap: 14px;
            }

            .delete-btn {
              cursor: pointer;
              background-color: lighten(red, 45%);
              border-radius: 50%;
              width: 58px;
              height: 58px;
              border: 1px solid rgba(red, 30%);
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 6px;
              svg {
                width: 22px;
                height: 22px;
              }
            }
          }

          .product-note {
            grid-column: 1 / -1;
            .product-note-area {
              height: 112px !important;
            }
          }
        }

        .addons-sizes-wrapper {
          display: grid;
          grid-template-columns: 1fr auto 1fr;
          gap: 12px;

          .sep {
            width: 1px;
            background-color: #bbb;
          }
        }

        .order-form-addons-fields-wrapper {
          display: grid;
          align-content: start;
          .add-addon-btn {
            height: 38px;
            display: flex;
            align-items: center;
            gap: 8px;
            transition: all 0.15s ease-out;
            border: 0;
            outline: 0;
            border-radius: 8px;
            padding: 2px 28px;
            padding-inline-start: 20px;
            background-color: #ccc;
            margin-bottom: 12px;
            justify-self: end;
            font-size: 14px;
            & > svg {
              width: 18px;
              height: 18px;
            }
            &.add-size-btn {
              justify-self: start;
            }
          }
          .addon-fields-wrapper {
            .fields-wrapper {
              display: grid;
              .name-price-delete {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 12px;

                .price-delete {
                  display: grid;
                  gap: 12px;
                  grid-template-columns: 1fr auto;
                  .delete-addon-btn {
                    cursor: pointer;
                    background-color: lighten(gray, 42%);
                    border-radius: 50%;
                    width: 48px;
                    height: 48px;
                    border: 1px solid rgba(gray, 32%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 1px;
                    svg {
                      width: 18px;
                      height: 18px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .client-fields-coll,
    .store-fields-coll {
      .ant-collapse-content {
        background-color: #f8f8f8;
      }
    }
    .client-data-fields-wrapper,
    .store-data-fields-wrapper {
      // background-color: #f8f8f8;
      // padding: 12px;
      border-radius: 12px;

      .fields-label {
        color: $secondary-text-color;
        font-size: 20px;
        margin-bottom: 12px;
      }

      .fields-wrapper {
        display: grid;
        gap: 4px 12px;
        @include mediaLg {
          grid-template-columns: 1fr 1fr 1fr;
        }
        // @include mediaXlg {
        //   grid-template-columns: 1fr 1fr 1fr;
        // }

        .custom-text-area {
          height: 112px !important;
        }
      }

      .client-rate-wrapper,
      .store-rate-wrapper {
        margin-bottom: 12px;
        display: flex;
        // flex-direction: column;
        align-items: center;
        gap: 8px;
        .rate-label {
          color: $secondary-text-color;
          font-size: 14px;
          text-align: center;
        }
        svg {
          width: 32px;
          height: 32px;
        }
      }
      // .store-rate-wrapper {
      //   grid-column: 1 / -1;
      // }

      .custom-radios-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        .labels-wrap {
          display: flex;
          flex-wrap: wrap;
          gap: 18px;
        }
      }
    }

    .client-note,
    .store-note {
      grid-column: 1 / -1;
    }

    .map-wrap {
      grid-column: 1 / -1;
    }
  }

  .order-note-wrapper {
    grid-column: 1 / -1;
    margin-top: 12px !important;
    .ant-form-item {
      margin-bottom: 0 !important;
    }
    .order-note {
      height: 112px !important;
    }
  }
}
