@import '../../scss/config';

.profile-menu-wrapper {
  display: flex;
  & > button {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0;
    height: auto !important;
  }
}

.main-app-profile-dropdown {
  min-width: 252px !important;
  .ant-dropdown-menu-item {
    padding: 0 !important;
    margin-bottom: 8px;
  }

  .settings-link {
    margin-top: 2px;
    padding: 10px 22px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid rgba(#000, 0.1);
    .wallet {
      color: $main-app-color;
    }
    .profile-img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 58px;
      height: 58px;
      background-color: #eee;
      border-radius: 50%;

      img {
        width: 58px;
        height: 58px;
        border: 2px solid $main-app-color;
        border-radius: 50%;
      }

      svg {
        width: 22px;
      }
      // img {
      //   width: 58px;
      //   height: 58px;
      //   border: 2px solid $main-app-color;
      //   border-radius: 50%;
      // }
    }
  }
  .profile-label {
    color: $main-app-color;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
    padding: 10px 22px !important;
    cursor: default;
  }

  .menu-link {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    padding: 10px 22px;

    svg {
      width: 20px;
      height: 20px;
      color: #6d6d6d;
    }
  }
  .signout-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    color: #ed0006;
    padding: 10px 22px !important;
    margin-bottom: 18px;
  }
}
