@import '../../../scss/config';

.home-orders-statistics {
  // display: flex;
  // flex-wrap: wrap;
  gap: 22px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(232px, 1fr));
  border-radius: 12px;
  margin-bottom: 22px;
  // background-color: #f3f6f9;
  // padding: 1.5rem;
  // & > div {
  //   flex: 248px;
  // }
}
