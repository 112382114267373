@import '../../scss/config';

.delivery-price-calc-page {
  min-height: calc(100vh - 60px);
  // background-color: #f7f8fa;
  border-top: 1px solid rgba(#000, 0.1);
  padding-top: 32px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .prices-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr 1fr;
    gap: 22px;

    .price-method-card {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border: 1px solid rgba(#000, 0.1);
      padding: 32px;
      border-radius: 12px;
      cursor: pointer;
      width: 332px;
      transition: all 0.2s ease-out;
      &.active {
        border-color: rgba(green, 0.3);
        background-color: rgba(green, 0.1) !important;
      }
      &:hover {
        background-color: rgba($main-app-color, 8%);
        box-shadow: 0 2px 8px rgba(#000, 0.1);
      }
      img {
        max-width: 132px;
        margin: auto;
      }

      .method-title {
        margin-top: 32px;
        font-size: 20px;
        text-align: center;
      }

      &:first-of-type {
        grid-column: 3 / 5;
      }
      &:nth-of-type(2) {
        grid-column: 2 / 4;
      }
      &:nth-of-type(3) {
        grid-column: 4 / 6;
      }

      position: relative;
      .btn-badge {
        position: absolute;
        right: auto;
        left: 8px;
        top: 8px;
        &.rtl {
          right: 6px;
          left: auto;
        }
        .checkmark-wrap {
          display: grid;
          place-items: center;
          width: 42px;
          height: 42px;
          background-color: green;
          border-radius: 50%;
        }

        .activate-btn {
          background-color: green;
          color: $white;
          border: 0;
          outline: 0;
          padding: 7px 16px;
          border-radius: 6px;
          font-size: 14px;
          height: fit-content;
          cursor: pointer;
        }
      }
    }
  }
}
