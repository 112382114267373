$menu-width: 256px;
$menu-width-collapsed: 80px;

.app-auth-layout {
  .auth-layout-content-wrapper {
    display: flex;
    flex-direction: row;
    .app-menu-wrapper,
    .app-menu-wrapper-parent {
      width: $menu-width;
      transition: all 0.2s linear;
      &.collapsed {
        width: $menu-width-collapsed;
      }
    }
    .auth-layout-content-full {
      width: calc(100% - $menu-width);
    }
    .auth-layout-content {
      width: calc(100% - $menu-width-collapsed);
    }
  }

  .page-header {
    margin-bottom: 22px;
    .custom-container {
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: space-between;
      min-height: 48px;
      background-color: #eee;
      border-radius: 8px;

      .page-title {
        display: flex;
        gap: 5px;
        align-items: center;
        text-transform: capitalize;
      }
    }
  }
}
