@import '../../../scss/config';

.message-list-item {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 12px;

  .user-img {
    img,
    svg {
      border-radius: 50%;
    }
  }

  .item-body {
    .name-time {
      display: flex;
      justify-content: space-between;
      gap: 4px;
      align-items: center;
      margin-bottom: 2px;
      .name {
        font-weight: bold;
        font-size: 15px;
      }
      .date {
        color: $secondary-text-color;
      }
    }
    .msg {
      margin-inline-start: 2px;
      font-size: 16px;
      color: $secondary-text-color;
    }
  }
}
