@import '../../../../scss/config';

.order-store-info-wrapper {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid rgba(#000, 0.1);
  .desc-map-wrapper {
    display: grid;
    gap: 16px;
    @include mediaLg {
      grid-template-columns: 1fr 1fr;
      .map-wrapper {
        margin-top: 4px;
        height: fit-content;
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }

  .img-name-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    flex-wrap: wrap;
    .store-logo {
      width: 32px;
    }
    svg {
      width: 32px;
      height: 32px;
    }
    img {
      width: 32px;
    }
  }
  .ant-descriptions-header {
    margin-bottom: 8px;
  }
}
