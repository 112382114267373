@import '../../../scss/config';

.driver-more-info-card {
  margin-top: 22px;
  background-color: $white;
  border-radius: 8px;
  padding: 20px 20px;
  box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12);
  display: flex;
  flex-direction: column;
  gap: 20px;

  .info-row {
    span {
      &:first-of-type {
        font-weight: bold;
      }
      &:last-of-type {
        color: $secondary-text-color;
        font-size: 18px;
      }
    }
  }
}
