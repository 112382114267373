#recaptcha-container {
  display: none;
}
@import './config';
@import './common';
@import './modals';

html {
  scroll-behavior: smooth;
  color: $main-text-color;

  body {
    font-size: 16px;
    width: 100% !important;
    overflow-y: auto;
  }
  &[lang='ar'] {
    body {
      @include bodyFont;
      button {
        @include bodyFont;
      }
    }
  }
  // &[lang='en'] {
  //   body {
  //     @include bodyFont-ar;
  //     // button {
  //     //   @include bodyFont-ar;
  //     // }
  //   }
  // }

  // &[lang="en"] {
  // 	@include bodyFont-en;
  // }
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  @include custom-scrollbar;
  // background-color: $white;
  // background-color: #f9f8f8;
}

.app {
  min-height: 100vh;
  // background-color: #f5f5f5;
  // background-color: #f7f8fb;
  background-color: $body-background-color;
  // display: grid;
  // overflow-x: hidden;

  // &.app-ltr {
  // 	@include bodyFont-en;
  // }

  margin: 0;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // @import "./css/fontello.css";
  // @import "./css/ionicons.css";
}

[class$='page'] {
  // min-height: 80vh;
  padding-bottom: 62px;
}

.shared-custom-page {
  padding: 22px 0;
  .page-title {
    h2 {
      font-size: 42px;
    }
    p {
      font-size: 18px;
    }
  }
}

[contenteditable='true'] {
  line-height: 1.8;
  // display: grid;
  // align-items: center;
}

.notifications-component {
  &.rtl {
    .notification__close {
      right: auto;
      left: 10px;
    }
  }
}

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: center;
}
.ant-table-tbody > tr > td,
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: center;
}

.ant-table-thead > tr > th,
.ant-table-thead > tr > th.ant-table-column-sort {
  background-color: #595f6f;
  color: $white;
  &::before {
    background-color: #bbb !important;
    // left: 0;
    // right: auto !important;
  }
}
.ant-table {
  background-color: transparent !important;
}

.antd-custom-table {
  // margin-top: 10px;
  table {
    border-spacing: 0 !important;
  }
  &.affiliate-table {
    tbody {
      & > tr {
        & > td {
          padding: 22px;
        }
      }
    }
    &.ltr {
      tbody {
        & > tr {
          & > td {
            &:first-of-type {
              border-radius: 8px 0 0 8px !important;
            }
            &:last-of-type {
              border-radius: 0 8px 8px 0 !important;
            }
          }
        }
      }

      // tbody > tr:nth-of-type(2n + 1) > td {

      // }
    }
  }

  .ant-table-thead {
    margin-bottom: 0;
    & > tr {
      border-radius: 4px;
    }
    & > tr > th {
      // background-color: #e3e3e3;
      // background-color: $white;
      color: $main-app-color;
      // border-bottom: 0;
      text-align: center;
    }
  }
  tbody {
    & > tr > td {
      // border-bottom: 1px solid #eee;
      // background-color: $main-bg-color;
      background-color: $white !important;
      padding-bottom: 26px;
      // &:first-child {
      //   border-radius: 0 12px 12px 0;
      // }
      // &:last-child {
      //   border-radius: 12px 0 0 12px;
      // }
    }
    & > tr {
      &:nth-of-type(2n + 1) {
        & > td {
          background-color: #f8f8fb !important;
        }
      }
    }
  }
  .has-read-more {
    color: #8b8b8b;
    font-size: 15px;
    display: flex;
    align-items: center;
    .read-more-span {
      padding-bottom: 4px;
    }
  }
  .ltr-text {
    direction: ltr;
  }

  .row-cell {
    // width: 132px;
    // display: grid;
    // justify-content: center;
    display: flex;
    justify-content: center;
    // &.row-index {
    //   span {
    //     color: $main-app-color;
    //     background-color: $white;
    //     font-size: 18px;
    //     border-radius: 10px;
    //     padding: 14px 10px;
    //     // min-width: 52px;
    //     min-height: 60px;
    //     display: grid;
    //     place-items: center;
    //     justify-content: center;
    //   }
    // }

    &.row-title {
      display: grid;
      .label-span {
        color: $main-app-color;
        text-align: start;
      }
    }
    &.asset-img-row {
      .img-preview-btn {
        cursor: pointer;
        border: 0;
        outline: 0;
        padding: 0;
        background-color: transparent;
        .asset-img {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        svg {
          width: 40px;
          border: 1px solid rgba(#000, 0.1);
        }
        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          object-position: top center;
          border-radius: 50%;

          border: 1px solid rgba(#000, 0.1);
        }
      }
    }
    &.row-text {
      color: #8b8b8b;
      text-align: center;
    }
    &.row-img {
      img {
        cursor: pointer;
        width: 132px;
        max-height: 92px;
        object-fit: cover;
        object-position: top center;
        border-radius: 4px;
      }
    }
    &.row-icon {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &.row-link {
      a {
        background-color: transparent;
        color: $main-app-color;
        border: 1px solid #000;
        padding: 8px 20px;
        border-radius: 8px;
        font-size: 14px;
        transition: all 0.2s ease-out;
        &:hover {
          background-color: $main-app-color;
          color: $white;
        }
      }
    }

    &.row-action-btn {
      .custom-btn {
        background-color: #b5b5b5;
        color: #222;
        padding: 8px 14px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        svg {
          width: 17px;
          height: 17px;
        }
      }
    }

    &.row-actions {
      // min-width: 262px;
      .action-btns-wrap {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: center;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .reports-btn,
      .edit-btn {
        background-color: #eee;
        color: $main-app-color;
        padding: 5px 24px;
        height: 34px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        cursor: pointer;
        svg {
          width: 16px;
          height: 16px;
          path {
            fill: $main-app-color;
          }
        }
      }
      .delete-btn {
        background-color: $main-app-color;
        padding: 10px 7px;
        border-radius: 12px;
        display: grid;
        place-items: center;
        cursor: pointer;
        svg {
          width: 18px;
          height: 14px;
        }
      }
    }

    &.row-circled-btn {
      .circled-btn {
        width: 40px;
        height: 40px;
        span {
          font-size: 18px;
        }
      }
    }
  }

  // sorting
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: $main-app-color !important;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover::before {
    background-color: #bbb !important;
  }

  .accept-btn,
  .reject-btn {
    // display: flex;
    align-items: center;
    border: 0;
    outline: 0;
    height: 40px;
    padding: 4px 22px;
    border-radius: 20px 5px 20px 5px;
  }
  .accept-btn {
    color: $main-app-color;
    cursor: pointer;
  }
  .reject-btn {
    background-color: #f9f8f8;
  }

  &.employees-table {
    .row-cell.row-actions .delete-btn {
      background-color: transparent;
      padding: 0;
    }
  }
}

.ant-select-rtl {
  width: 100%;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  @include mediaLg {
    justify-content: center;
  }
}

.popover-answers-wrap {
  display: grid;
  grid-gap: 8px;
  list-style-type: disc;
  padding-right: 22px;
  max-width: 362px;
}

.ant-popover-inner-content {
  max-width: 280px;
}

.ant-avatar.ant-avatar-circle.ant-avatar-icon {
  line-height: 34px !important;
}

.ant-btn-round.ant-btn-lg {
  padding: 6.4px 10px;
}

.custom-shared-btn {
  border: 0;
  outline: 0;
  height: 40px;
  padding: 4px 22px;
  border-radius: 20px 5px 20px 5px;
  display: flex;
  gap: 6px;
  align-items: center;
  // & > span {
  //   padding-bottom: 6px;
  // }
  & > svg {
    width: 18px;
    height: 18px;
    // margin-top: 4px;
  }
  &.dimmed {
    background-color: #ddd;
    color: #444;
  }
}

.custom-page-title-profile-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification__item--success {
  background-color: lighten($main-app-color, 14%);
  // border: 0;
  border-left-width: 5px;
  .notification__close {
    background-color: $white;
    width: 22px;
    height: 22px;

    &::after {
      color: $main-app-color;
      font-size: 20px;
      top: 56%;
    }
  }
}
.notification__item--danger {
  background-color: lighten(rgb(185, 56, 56), 10%);
  border-radius: 5px;
  // border: 0;
  border-left-width: 5px;
  .notification__title {
    color: $white;
  }
  .notification__close {
    background-color: $white;
    width: 22px;
    height: 22px;

    &::after {
      color: #dc3545;
      font-size: 20px;
      top: 56%;
    }
  }
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 38px !important;
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 20px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  border-radius: 4px;
  height: 45vh;
  @include mediaLg {
    height: 65vh;
  }
  width: 100%;
  object-fit: cover;
  object-position: top center;
}
.gallery-wrap .porduct-details-fallback-img img {
  border-radius: 4px;
  height: 45vh;
  @include mediaLg {
    height: 65vh;
  }
  width: 100%;
  object-fit: cover;
  object-position: top center;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
// .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-vertical > .ant-menu-item {
  height: 42px;
  line-height: 42px;
  gap: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.15s ease-out;
  & > svg {
    width: 18px;
    height: 18px;
  }
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: lighten($main-app-color, 16%);
  color: $main-text-color;
  box-shadow: 0 1px 8px 1px rgba(#000, 0.15);
}

.breadcrumb-title {
  background-color: #f3eafb;
  padding: 18px 0;
  h2 {
    color: $secondary-app-color;
    padding: 12px 14px;
    padding-bottom: 4px;
    font-size: 28px;
  }
  .date-wrap {
    color: #6d6d6d;
    padding: 0 14px;
  }
}

.ant-table-selection-column {
  padding-right: 18px !important;
  padding-left: 18px !important;
  .ant-radio {
    font-size: 28px !important;
    .ant-radio-inner {
      width: 28px;
      height: 28px;
      &::after {
        width: 28px;
        height: 28px;
        margin-top: -14px;
        margin-left: -14px;
      }
    }
  }
}

.copyright-p {
  color: #c4c4c4;
  text-align: center;
}

.ant-notification-notice-message {
  margin-bottom: 0 !important;
}

.striked {
  position: relative;
  color: #6d6d6dc4;
  padding: 4px 8px;

  .line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    &::after {
      position: absolute;
      content: '';
      width: 90%;
      height: 2px;
      top: 50%;
      left: 5%;
      background-color: #6d6d6d;
      transform: translate(0, -50%) rotate(-15deg);
    }
  }
}

.shared-empty-wrapper {
  margin-top: 42px;
  display: grid;
  justify-content: center;
  text-align: center;
  .empty-img {
    max-width: 100%;
    margin-bottom: 22px;
  }
}

.ant-pagination-item {
  background-color: $white !important;
  border: 1px solid rgba(#000, 0.1) !important;
}
.ant-pagination-item-active {
  background-color: #58b030 !important;
  a {
    color: $white !important;
  }
}

.pagination-total-wrapper {
  margin-top: 22px;
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  align-items: center;
  justify-content: space-between;

  .ant-pagination-item-link {
    background-color: lighten($main-app-color, 30%);
    span {
      color: $main-app-color;
    }
  }

  .ant-pagination-item-active {
    background-color: $main-app-color;
    a {
      color: $white;
    }
  }

  .total-wrapper {
    display: flex;
    gap: 4px;
    align-items: center;
    color: #b7b7b7;
    margin-right: auto;
  }
}

.fcm-foreground-wrapper {
  border-radius: 6px;
  cursor: pointer;
  background-color: lighten($main-app-color, 34%);
  padding: 32px 24px;
  // .ant-notification-notice-description {
  //   padding: 8px 14px;
  //   padding-bottom: 0;
  // }
}

//
//
//
//
//
//
//
//
//
//

.ant-form.custom-shared-form {
  max-width: 1000px;
  .form-body {
    &.with-bg {
      background-color: $main-bg-color;
      // padding: 22px 24px;
      padding: 18px 20px;
      border-radius: 12px;

      .form-header {
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 18px;
        color: $main-text-color !important;
      }

      .cancel-btn {
        // background-color: $white;
      }
    }

    .custom-text-field-wrapper {
      position: relative;
      &.sm {
        .ant-form-item {
          margin-bottom: 16px;
          .ant-form-item-row {
            input.custom-text-field {
              height: 52px;
              padding: 2px 12px;
              border-radius: 10px;
              font-size: 14px;
            }
          }

          &.focused {
            .ant-form-item-row {
              input.custom-text-field {
                padding: 2px 18px;
                padding-top: 14px;
              }
            }
            & + .custom-label {
              top: 2px;
              left: 12px;
              scale: 0.92;
            }
          }
        }
        .custom-label {
          left: 18px;
          top: 15px;
          font-size: 13px;
        }

        .prefix-span {
          left: auto;
          right: 8%;
          top: 18px;
          font-size: 11px;
        }

        &.with-prefix {
          .ant-form-item {
            &.focused {
              .ant-form-item-row {
                input.custom-text-field {
                  padding-inline-end: 36px;
                }
              }
            }
          }
        }

        &.rtl {
          .ant-form-item {
            &.focused {
              & + .custom-label {
                right: 12px;
                left: auto;
              }
            }
          }
          .custom-label {
            right: 18px;
            left: auto;
          }
          .prefix-span {
            right: auto;
            left: 8%;
          }
        }
      }
      .ant-form-item {
        .ant-form-item-row {
          input.custom-text-field {
            transition: all 0.2s ease-out;
            // border: 1px solid #d5d9dd;
            padding: 7px 18px;
            border-radius: 16px;
            height: 70px;
            background-color: $secondary-background-color;
            &.ant-input-status-error {
              border-color: #ff4d4f;
            }
          }
        }
        .ant-form-item-explain-error {
          padding: 0 6px;
          font-size: 14px;
        }
        &.focused {
          .ant-form-item-row {
            input.custom-text-field {
              padding: 7px 22px;
              padding-top: 18px;
            }
          }
          & + .custom-label {
            top: 5px;
            left: 14px;
            scale: 0.92;
          }
        }
      }
      .custom-label {
        pointer-events: none;
        padding-bottom: 0;
        position: absolute;
        z-index: 1;
        left: 22px;
        top: 24px;
        color: #98a6b3;
        transition: all 0.2s ease-out;
      }

      .prefix-span {
        position: absolute;
        z-index: 1;
        pointer-events: none;
        left: auto;
        right: 8%;
        top: 28px;
        color: #98a6b3;
        font-size: 12px;
      }

      &.with-prefix {
        .ant-form-item {
          &.focused {
            .ant-form-item-row {
              input.custom-text-field {
                padding-inline-end: 42px;
              }
            }
          }
        }
      }

      &.rtl {
        .ant-form-item {
          &.focused {
            & + .custom-label {
              right: 14px;
              left: auto;
            }
          }
        }
        .custom-label {
          right: 22px;
          left: auto;
        }
        .prefix-span {
          right: auto;
          left: 8%;
        }
      }
    }
    .custom-text-area-wrapper {
      position: relative;
      .ant-form-item {
        .ant-form-item-row {
          textarea.custom-text-area {
            transition: all 0.2s ease-out;
            border: 1px solid #d5d9dd;
            padding: 28px 18px;
            height: 162px;
            border-radius: 16px;
            background-color: $secondary-background-color;
            &.ant-input-status-error {
              border-color: #ff4d4f;
            }
          }
        }
        .ant-form-item-explain-error {
          padding: 0 6px;
          font-size: 14px;
        }
        &.focused {
          .ant-form-item-row {
            textarea.custom-text-area {
              padding: 28px 22px;
            }
          }
          & + .custom-label {
            top: 5px;
            left: 14px;
            scale: 0.92;
          }
        }
      }
      .custom-label {
        pointer-events: none;
        padding-bottom: 0;
        position: absolute;
        z-index: 1;
        left: 22px;
        top: 24px;
        color: #98a6b3;
        transition: all 0.2s ease-out;
      }

      &.rtl {
        .ant-form-item {
          &.focused {
            & + .custom-label {
              right: 14px;
              left: auto;
            }
          }
        }
        .custom-label {
          right: 22px;
          left: auto;
        }
      }
    }

    .custom-select-field-wrapper {
      position: relative;
      &.sm {
        .ant-form-item {
          margin-bottom: 16px;
          .ant-form-item-row {
            .custom-select-field {
              .ant-select-selector {
                min-height: 52px;
                border-radius: 10px;

                .ant-select-selection-item {
                  padding-inline-start: 6px;
                  font-size: 14px;
                }

                .ant-select-selection-search {
                  line-height: 68px;
                }
              }
            }
          }

          &.focused {
            .custom-select-field .ant-select-selector {
              padding: 2px 18px;
              padding-top: 14px;
            }
            & + .custom-label {
              top: 2px;
              left: 12px;
              scale: 0.92;
            }
          }
        }
        .custom-label {
          left: 18px;
          top: 15px;
          font-size: 13px;
        }

        .prefix-span {
          left: auto;
          right: 8%;
          top: 18px;
          font-size: 11px;
        }

        &.with-prefix {
          .ant-form-item {
            &.focused {
              .ant-form-item-row {
                input.custom-text-field {
                  padding-inline-end: 36px;
                }
              }
            }
          }
        }

        &.rtl {
          .ant-form-item {
            &.focused {
              & + .custom-label {
                right: 12px;
                left: auto;
              }
            }
          }
          .custom-label {
            right: 18px;
            left: auto;
          }
          .prefix-span {
            right: auto;
            left: 8%;
          }
        }
      }
      .ant-form-item {
        .ant-form-item-row {
          .custom-select-field {
            &.ant-select-status-error {
              .ant-select-selector {
                border-color: #ff7875;
              }
            }
            .ant-select-selector {
              transition: all 0.2s ease-out;
              border: 1px solid #d5d9dd;
              padding: 7px 18px;
              border-radius: 16px;
              min-height: 70px;
              background-color: $secondary-background-color;

              .ant-select-selection-search {
                line-height: 85px;
              }
              &.ant-input-status-error {
                border-color: #ff4d4f;
              }

              .ant-select-selection-item {
                padding-inline-start: 6px;
              }
            }
          }
          .custom-select-field .ant-select-selector input {
            // height: 100% !important;
            padding: 0 12px;
          }
          .custom-select-field.ant-select-multiple .ant-select-selector input {
            padding: 0;
          }
        }
        .ant-form-item-explain-error {
          padding: 0 6px;
          font-size: 14px;
        }
        &.focused {
          .custom-select-field .ant-select-selector {
            padding: 7px 22px;
            padding-top: 22px;
          }
          & + .custom-label {
            top: 5px;
            left: 14px;
            scale: 0.92;
          }
        }
      }
      .custom-label {
        pointer-events: none;
        padding-bottom: 0;
        position: absolute;
        z-index: 1;
        left: 22px;
        top: 24px;
        color: #98a6b3;
        transition: all 0.2s ease-out;
      }

      &.rtl {
        .ant-form-item {
          &.focused {
            & + .custom-label {
              right: 14px;
              left: auto;
            }
          }
        }
        .custom-label {
          right: 22px;
          left: auto;
        }
      }
    }

    .password-field-wrap {
      position: relative;
      &.with-eye-icon {
        input {
          padding-left: 42px !important;
        }
      }
      .eye-icon-btn {
        position: absolute;
        left: 14px;
        top: 18%;
        cursor: pointer;
        padding: 4px;
        z-index: 1;
        img {
          height: 18px;
        }
      }
      &.ltr {
        .eye-icon-btn {
          left: auto;
          right: 14px;
        }
      }
    }

    .country-code-phone-wrap {
      padding-bottom: 24px;
      position: relative;
      &.error {
        .custom-phone-input {
          border-color: #ff4d4f;
        }
      }
      .custom-label {
        pointer-events: none;
        padding-bottom: 0;
        position: absolute;
        z-index: 1;
        left: 22px;
        top: 24px;
        color: #98a6b3;
        transition: all 0.2s ease-out;
      }
      &.focused,
      .PhoneInput--focus {
        input {
          padding-top: 17px !important;
        }
      }
      &.focused .custom-label,
      .PhoneInput--focus + .custom-label {
        top: 5px;
        left: 14px;
        scale: 0.92;
      }

      &.rtl {
        .PhoneInputCountry {
          border-left: 1px solid #d5d9dd;
        }
        &.focused .custom-label,
        .PhoneInput--focus + .custom-label {
          right: calc(75px + 14px);
          left: auto;
        }

        .custom-label {
          right: calc(82px + 14px);
          left: auto;
        }
      }
      &.ltr {
        .PhoneInputCountry {
          border-right: 1px solid #d5d9dd;
        }
        &.focused .custom-label,
        .PhoneInput--focus + .custom-label {
          left: calc(75px + 14px);
          right: auto;
        }

        .custom-label {
          left: calc(82px + 14px);
          right: auto;
        }
      }

      .PhoneInputCountry {
        margin: 0 !important;
        padding: 2px 2px;
        display: flex;
        width: 74px;
        justify-content: center;
        &::before {
          content: '';
          width: 22px;
          height: 22px;
          background-image: url('../assets/imgs/icons/bx-chevron-down.svg');
          background-repeat: no-repeat;
          background-size: cover;
        }
      }

      .custom-phone-input {
        display: grid;
        align-items: center;
        grid-template-columns: auto 1fr;
        border: 1px solid #d5d9dd;
        border-radius: 16px;
        overflow: hidden;
        background-color: #f7f8fa;
        .PhoneInputCountrySelectArrow {
          display: none;
          // order: -1;
          height: 8px;
          width: 8px;
        }
        .PhoneInputCountryIcon {
          height: 18px;
          width: 32px;
          width: auto;
          border: 0;
          padding: 0 4px;
          background-color: transparent;
          box-shadow: none;
          img {
            border-radius: 2px;
          }
        }
      }
      input,
      select {
        height: 70px;
      }
      input {
        border-radius: 4px;
        height: 70px;
        border: 0;
        // box-shadow: none;
        outline: 0;
        padding: 2px 18px;
        transition: all 0.15s ease-out;
        // direction: ltr;
        // text-align: right;
        &:hover,
        &:focus {
          border-color: $main-app-color;
        }
        &::placeholder {
          color: #c4c4c4;
        }
      }
      .error-p {
        position: absolute;
      }
    }

    .product-img-wrap {
      h3 {
        margin-bottom: 8px;
        padding: 0 6px;
        color: #555;
      }
    }

    .editor-wrapper {
      .editor-title {
        color: #666;
        margin-bottom: 8px;
        font-size: 1rem;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .toolbarClassName {
        direction: ltr;
      }
      .wrapperClassName {
        margin-bottom: 4px;
        background-color: #f5f5f5;
        .editorClassName {
          padding: 0 18px;
          min-height: 180px;
        }
      }
    }

    .fields-array-ul {
      margin-top: 12px;
      display: grid;
      gap: 18px 32px;
      .field-delete-li {
        display: grid;
        grid-template-columns: 1fr 1fr 42px;
        gap: 32px;

        .field-icon {
          height: 62px;
          background-color: #1bee95;
          padding: 8px 16px;
          display: grid;
          place-items: center;
          img {
            height: 30px;
          }
        }
        .delete-field-btn {
          align-self: center;
          // margin-top: 8px;
        }
      }
      .bill-field-delete-li {
        background-color: #efefef;
        padding: 18px 20px;
        padding-bottom: 0;
        border-radius: 8px;
        display: grid;
        grid-template-columns: 1fr 42px;
        gap: 32px;
        input,
        .ant-select-selector,
        .receipt-date-picker {
          background-color: $white !important;
        }

        .product-id-quantity-wrap,
        .warehoues-expiration-date-wrap {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 22px;
          .custom-date-input {
            align-self: start;
          }
        }
        .field-icon {
          height: 62px;
          background-color: #1bee95;
          padding: 8px 16px;
          display: grid;
          place-items: center;
          img {
            height: 30px;
          }
        }
        .delete-field-btn {
          align-self: center;
          // margin-top: 8px;
        }
      }

      &.with-border {
        margin-bottom: 22px;
        padding-bottom: 32px;
        border-bottom: 1px solid rgba(#000, 0.2);
      }
      &.without-padding {
        padding-bottom: 0;
      }
    }

    .product-img-wrap {
      padding-bottom: 32px;
      margin-bottom: 22px;
      border-bottom: 1px solid rgba(#000, 0.2);
    }

    .add-new-field-btn {
      font-weight: bold;
      text-transform: capitalize;
      background-color: $main-app-color;
      color: $white;
      border-radius: 10px;
      padding: 10px 18px;
      border: 0;
      outline: 0;
      min-height: 44px;
      // margin-top: 22px;
      width: fit-content;
      cursor: pointer;
    }

    .total-fields-price {
      display: flex;
      align-items: center;
      gap: 18px;
      padding: 4px 12px;
      margin: 8px 0;
      background-color: #444;
      padding: 4px 22px;
      color: $white;
      border-radius: 12px;
      .total-value {
        min-height: 52px;
        display: grid;
        place-items: center;
        font-size: 20px;
        direction: ltr;
        line-height: 2;
      }
    }

    .custom-picker-wrap {
      display: grid;
    }
    .custom-date-input {
      display: grid;
      .ant-picker {
        background-color: #f7f8fa !important;
        border: 1px solid #d5d9dd !important;
        border-radius: 16px;
      }
      .label-span {
        margin-bottom: 8px;
        display: inline-block;
        padding: 0 6px;
        color: #555;
      }
      input {
        height: 42px;
        border-radius: 8px;
        // background-color: $main-bg-color !important;
        border: 0;
        padding: 10px 18px;
        padding-right: 4px;
        outline: 0;
        transition: all 0.2s ease-out;
        &:focus {
          // box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        }
      }
    }

    .receipt-date-picker {
      background-color: #f1f2f3 !important;
      height: 62px;
      border-radius: 8px;
      input {
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
    }
    .error-p {
      color: lighten(red, 10%);
      font-size: 14px;
    }
  }

  .submit-cancel-btns {
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .submit-btn {
    background-color: $main-app-color;
    color: $white;
    border-radius: 10px;
    padding: 10px 18px;
    border: 0;
    outline: 0;
    min-height: 48px;
    min-width: 122px;
    // max-width: 182px;
    justify-self: end;
    margin-top: 22px;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    transition: all 0.2s ease-out;
    &:hover {
      box-shadow: 0 0 4px rgba($main-text-color, 0.2);
      background-color: lighten($main-app-color, 5%);
    }
    .svg-icon {
      .fill-path {
        fill: $white;
      }
    }
  }

  .cancel-btn {
    background-color: lighten($secondary-text-color, 60%);
    color: $main-text-color;
    border-radius: 10px;
    padding: 10px 18px;
    border: 0;
    outline: 0;
    min-height: 48px;
    min-width: 100px;
    // max-width: 182px;
    justify-self: end;
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    transition: all 0.2s ease-out;
    &:hover {
      background-color: lighten($secondary-text-color, 65%);
      box-shadow: 0 0 4px rgba($main-text-color, 0.2);
    }
    .svg-icon {
      .fill-path {
        fill: $main-text-color;
      }
    }
  }
}

// /////////////////
// .ant-select-dropdown {
//   &.scrollable {
//     max-height: 300px;
//   }
// }

.select-load-more-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
  .select-load-more-btn {
    border: 0;
    outline: 0;
    padding: 4px 12px;
    background-color: $main-app-color;
    color: $white;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
  }
}

.quote-steps-indicator {
  .ant-steps-item-container {
    .ant-steps-item-tail {
      margin-inline-start: 62px;
      padding: 4px 17px;
      pointer-events: none;
      &::after {
        height: 6px;
        background-color: #e9f3fb !important;
        border-radius: 99px;
      }
    }

    .ant-steps-item-icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e9f3fb;
      z-index: 1;
      position: relative;
      .ant-steps-icon {
        color: #98a6b3;
      }
    }

    .ant-steps-item-content {
      margin-top: 2px;
      .ant-steps-item-title {
        color: #668199;
        font-weight: bold;
        white-space: nowrap;
        font-size: 14px !important;
      }
    }
  }

  .ant-steps-item {
    &.ant-steps-item-active,
    &.ant-steps-item-finish {
      .ant-steps-item-icon {
        border-color: #15b254;
        background-color: #edfdf3;
        .ant-steps-icon {
          color: #15b254;
        }
      }

      .ant-steps-item-content {
        .ant-steps-item-title {
          color: #15b254;
        }
      }
    }
    &.ant-steps-item-finish {
      .ant-steps-item-tail {
        &::after {
          background-color: #15b254 !important;
        }
      }
    }
  }
}

.ant-modal-wrap {
  @include custom-scrollbar;
}

.order-status-tag {
  background-color: #eee;
  font-size: 14px;
  padding: 3px 10px;
  border-radius: 6px;
  height: fit-content;

  &._1 {
    background-color: #58b030;
    color: $white;
  }
  &._2 {
    background-color: $main-app-color;
    color: $white;
  }
  &._3 {
    background-color: #668199;
    color: $white;
  }
}

.table-add-btn-filter-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  justify-content: space-between;
}
.new-filter-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  .svg-icon {
    .fill-path {
      fill: $white;
    }
  }
}

.shared-table-filter-form-wrapper {
  margin-bottom: 18px;

  .shared-filter-form {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
    position: relative;

    &.view-actions {
      .form-body {
        margin-inline-end: 92px;
      }
    }
    .form-body {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      transition: all 0.3s ease-in-out;
    }

    .search-text-input {
      width: 182px;
      height: 32px;
    }

    .form-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      position: absolute;
      &.ltr {
        right: 0;
        left: auto;
      }
      &.rtl {
        left: 0;
        right: auto;
      }

      .submit-btn {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        .svg-icon {
          .fill-path {
            fill: $white;
          }
        }
      }
      .reset-btn {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $main-bg-color;
        .svg-icon {
          .fill-path {
            fill: $main-app-color;
          }
        }
      }
    }
  }
}

.custom-radios-wrap {
  .radios-label {
    color: $secondary-text-color;
    font-size: 1rem;
    margin-bottom: 4px;
  }
  .labels-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    .label-span {
      // color: #0b0d0f;
      color: $secondary-text-color;
      font-size: 18px;
    }
    .radio-input-label {
      margin-bottom: 18px;
      width: fit-content;
      display: flex;
      gap: 5px;
      align-items: center;
      cursor: pointer;

      input {
        display: none;
      }
      .svg-radio-icon-wrap {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.selected {
        .label-span {
          color: $main-text-color;
        }
      }
    }
  }
}

.table-filter-delete-all-btn {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  .delete-all-btn {
    margin-inline-start: auto;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: lighten(red, 35%);
    color: red;
    border: 0;
    outline: 0;
    height: 32px;
    border-radius: 4px;
    padding: 2px 18px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.15s ease-out;
    &:hover {
      background-color: lighten(red, 38%);
      box-shadow: 0 2px 3px rgba(#000, 0.06);
    }
  }
}
