.order-driver-info {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid rgba(#000, 0.1);
  display: grid;
  gap: 16px;
  &.live-location {
    grid-template-columns: 1fr 1fr;

    .map-wrapper {
      height: fit-content;
      border-radius: 8px;
      overflow: hidden;
    }
  }

  .img-name-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    flex-wrap: wrap;
    .driver-img {
      width: 32px;
    }
    svg {
      width: 32px;
      height: 32px;
    }
    img {
      width: 32px;
    }
  }

  .ant-descriptions-header {
    margin-bottom: 8px;
  }
}
