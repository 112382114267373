@import '../../../scss/config';

.status-select-option-wrapper {
  .select-label-wrap {
    display: flex;
    gap: 6px;
    align-items: center;

    .select-label {
      color: $secondary-text-color;
      margin-bottom: 2px;
      font-size: 14px;
    }

    .ant-select-selector {
      height: 42px;
      input {
        height: 42px;
        line-height: 42px;
      }
      .ant-select-selection-item {
        line-height: 38px;
      }
    }
  }
}
